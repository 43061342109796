import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl, useIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';

import { AvatarSmall, NamedLink, ResponsiveImage } from '../../components';
import CommentsIcon from './images/comments.svg';

import css from './AuthListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const AuthListingCardComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    reviews,
    renderSizes,
  } = props;

  const intl = useIntl();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(currentListing.author);
  const authorName = author.attributes.profile.displayName;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith('listing-card'))
    : [];

  const date = new Date(currentListing.attributes.createdAt);
  const now = new Date();
  const timeDifferenceInSeconds = Math.floor((now - date) / 1000);

  let timeAgo;
  if (timeDifferenceInSeconds < 60) {
    timeAgo = intl.formatRelativeTime(-timeDifferenceInSeconds, 'second');
  } else if (timeDifferenceInSeconds < 3600) {
    timeAgo = intl.formatRelativeTime(-Math.floor(timeDifferenceInSeconds / 60), 'minute');
  } else if (timeDifferenceInSeconds < 86400) {
    timeAgo = intl.formatRelativeTime(-Math.floor(timeDifferenceInSeconds / 3600), 'hour');
  } else {
    timeAgo = intl.formatRelativeTime(-Math.floor(timeDifferenceInSeconds / 86400), 'day');
  }

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.card}      >
        <div className={css.cardOverlay} />
        <ResponsiveImage
          rootClassName={css.cardImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        <div className={css.info}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.mainInfo}>
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
            <AvatarSmall user={author} className={css.providerAvatar} />
          </div>
          <div className={css.time}>
            <span>{timeAgo}</span>
            {reviews && reviews.lengh > 0 &&
              <div className={css.reviews}>
                {reviews.length}
                <img className={css.commentsIcon} src={CommentsIcon} alt="Comments" />
              </div>
            }
          </div>
        </div>
      </div>

    </NamedLink>
  );
};

AuthListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

AuthListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(AuthListingCardComponent);
